const paths = {
  login: "/nl/login/",
  register: "/nl/registreren/",
  "forgot-password": "/nl/wachtwoord-vergeten/",
  "activate-customer": "/nl/account-activeren/:userId/:token/",
  "reset-password": "/nl/reset-wachtwoord/:userId/:token/",
  dashboard: "/nl/account/",
  invalidToken: "/nl/account/invalid_token/",
  orders: "/nl/account/bestellingen/",
  support: "https://knowledgebase.boldsmartlock.com/nl/knowledge",
  returns: "https://www.retourneren.nl/boldsmartlock",
  faq: "/nl/veelgestelde-vragen/",
  products: "/nl/producten/",
}

export default paths
