import { createClient, errorExchange, fetchExchange } from "urql";
import { getAuthToken } from "@utils/auth";
import useLogout from "@hooks/useLogout";

const makeClient = (url?: string) => {
  const client = createClient({
    url: `${url || process.env.GATSBY_SHOPIFY_GRAPQL_URL}`,
    exchanges: [
      errorExchange({
        onError: (error) => {
          const isAuthError = error.graphQLErrors.some((e) =>
            e.message.includes("invalid_token")
          );
          if (isAuthError) {
            useLogout();
          }
        },
      }),
      fetchExchange,
    ],
    fetchOptions: () => {
      const token = getAuthToken();
      const headers: any = {};

      if (process.env.GATSBY_STOREFRONT_ACCESS_TOKEN) {
        headers["X-Shopify-Storefront-Access-Token"] =
          process.env.GATSBY_STOREFRONT_ACCESS_TOKEN;
      }

      if (process.env.GATSBY_STOREFRONT_ACCESS_TOKEN) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      return headers ? { headers: headers } : {};
    },
  });
  return client;
};

export default makeClient;
