import {gtagEnabled} from "@utils/tracking/index"
import {Cart, CartLineEdge} from "@graphql/shopify/generated"

export const trackRemoveFromCart = (cart: Cart, lineItemToRemove?: CartLineEdge) => {
    if (gtagEnabled() && lineItemToRemove) {
        gtag("event", "remove_from_cart", {
            currency: cart.estimatedCost.totalAmount.currencyCode,
            value: lineItemToRemove.node.estimatedCost.totalAmount,
            items: [{
                item_id: lineItemToRemove.node.merchandise.sku,
                item_name: lineItemToRemove.node.merchandise.title,
                price: lineItemToRemove.node.merchandise.price,
                quantity: lineItemToRemove.node.quantity,
            }]
        })
    }
}