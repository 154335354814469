import {CouponType} from "@context/types"

export const AUTH_TOKEN = "jwtToken"
export const USER_INFO = "userInfo"
export const defaultLocale = "en"

export const defaultProductVariants = ["SX-33"]

export const strapiCouponTypeMap: {[key: string]: CouponType} = {
    "referral_code": "referral-code",
    "coupon_code": "coupon-code",
}