import * as React from 'react'
import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { toStorefrontId } from '@utils/toStorefrontId'
import { fromStorefrontId } from '@utils/fromStorefrontId'
import fetch from 'isomorphic-fetch'
import useAllBundles from '@hooks/useAllBundles'
import { trackAddToCart } from '@utils/tracking/trackAddToCart'
import { trackViewCart } from '@utils/tracking/trackViewCart'
import {
    AddToCartMutationVariables,
    Cart,
    CartLineInput,
    CartRemoveItemMutationVariables,
    UpdateCartMutationVariables,
    useAddDiscountCodeMutation,
    useAddToCartMutation,
    useCartRemoveItemMutation,
    useCheckoutDiscountCodeApplyMutation,
    useCreateCartMutation,
    useCreateCheckoutMutation,
    useRetrieveCartQuery,
    useUpdateCartMutation,
} from '@graphql/shopify/generated'

import { trackRemoveFromCart } from '@utils/tracking/trackRemoveFromCart'
import { ActiveDiscountCode, Bundle, LineItemsProps, SiteBundles, StoreContextProps } from '@context/types'
import { Locales } from '../i18n/types'
import { getI18nMessages } from '../i18n/messages'
import { defaultLocale } from '../constants'
import useMediaQuery from '@hooks/useMediaQuery'

const defaultValues = {
    cart: null,
    isOpen: false,
    isMenuOpen: false,
    isOverlayOpen: false,
    onOpenOverlay: () => {},
    onCLoseOverlay: () => {},
    onOpenMenu: () => {},
    onCloseMenu: () => {},
    loading: false,
    locale: 'en' as Locales,
    onOpen: () => {},
    onClose: () => {},
    addVariantToCart: (a: string, b: number) => {},
    removeLineItem: (a: string, b: string) => {},
    addVariantsToCart: (lineItemsToUpdate: LineItemsProps[]) => {},
    updateLineItem: (a: string, b: string, c: number, d: boolean) => {},
    getDiscountData: () => {},
    startCheckout: () => {},
    discountTotals: null,
    didJustAddToCart: false,
    upsellBundles: null,
    activeDiscountCode: null,
    setSiteBundles: (a: SiteBundles[]) => {},
    setLocale: (a: Locales) => {},
    allBundles: null,
    hideSnackbar: false,
    errorCoupon: null,
    initializeCheckout: () => {},
    showDiscountModal: false,
    setShowDiscountModal: (a: boolean) => {},
    setHideSnackbar: (a: boolean) => {},
    addDiscountCodeToCart: async (cartID: string, discountCodeToAdd: string) => {},
    cartError: null,
}

export const StoreContext = createContext<StoreContextProps>(defaultValues)

const isBrowser = typeof window !== `undefined`
const SHOPIFY_CART_ID = `shopify_cart_id`

export const StoreProvider = ({ children }: { children: ReactNode }) => {
    const isMobile = isBrowser ? useMediaQuery('(max-width: 768px)') : false
    const existingCartID = isBrowser ? localStorage.getItem(SHOPIFY_CART_ID) : null
    const retrieveCartOptions = existingCartID ? { variables: { id: existingCartID } } : { pause: true }
    const [{}, updateCartMutation] = useUpdateCartMutation()
    const [{}, removeItemMutation] = useCartRemoveItemMutation()
    const [{ fetching: createCartFetching }, createCartMutation] = useCreateCartMutation()
    const [{ data: cartData, fetching: retrieveCartFetching }, retrieveCartQuery] =
        useRetrieveCartQuery(retrieveCartOptions)

    const [{ fetching: addToCartFetching }, addToCartMutation] = useAddToCartMutation()
    const [{ fetching: createCheckoutFetching }, createCheckout] = useCreateCheckoutMutation()
    const [discountTotals, setDiscountTotals] = useState(defaultValues.discountTotals)
    const [{ fetching: addDiscountCodeFetching }, addDiscountMutation] = useAddDiscountCodeMutation()
    const [{ fetching: addDiscountToCheckoutFetching }, addDiscountToCheckout] = useCheckoutDiscountCodeApplyMutation()
    const [cart, setCart] = useState<Cart | null>(defaultValues.cart)
    const [showDiscountModal, setShowDiscountModal] = useState(false)
    const [hideSnackbar, setHideSnackbar] = useState(false)
    const [errorCoupon, setErrorCoupon] = useState<ActiveDiscountCode | null>(null)
    const [cartError, setCartError] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const [didJustAddToCart, setDidJustAddToCart] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [upsellBundles, setUpsellBundles] = useState<Bundle[] | null>(null)
    const [siteBundles, setSiteBundles] = useState<SiteBundles[]>()
    const [allBundles, setAllBundles] = useState<Bundle[] | null>(null)
    const [locale, setLocale] = useState('')
    const [isOverlayOpen, setIsOverlayOpen] = useState(false)
    const [cartLoaded, setCartLoaded] = useState(false)
    const [activeDiscountCode, setActiveDiscountCode] = useState<ActiveDiscountCode | null>(null)

    useEffect(() => {
        if (!retrieveCartFetching && !cartLoaded) {
            initializeCheckout()
            setCartLoaded(true)
        }
    }, [retrieveCartFetching])

    const messages = useMemo(() => {
        return getI18nMessages((locale || defaultLocale) as Locales)
    }, [locale])

    const callUpdateCartMutation = async (variables: UpdateCartMutationVariables, addToCart: boolean) => {
        await updateCartMutation({ ...variables })
            .then(({ data }) => {
                if (data?.cartLinesUpdate?.cart) {
                    setCart(data.cartLinesUpdate.cart as Cart)
                    setLoading(false)
                    if (addToCart) {
                        trackAddToCart(data.cartLinesUpdate.cart as Cart, variables.lines as CartLineInput[])
                    }
                } else {
                    console.log(data, 'error')
                }
            })
            .catch(error => {
                console.log(error, 'error')
            })
    }

    const callAddToCart = async (variables: AddToCartMutationVariables) => {
        await addToCartMutation({ ...variables })
            .then(({ data }) => {
                if (data?.cartLinesAdd?.cart) {
                    setCart(data.cartLinesAdd.cart as Cart)
                    setLoading(false)
                    trackAddToCart(data.cartLinesAdd.cart as Cart, variables.lines as CartLineInput[])
                } else {
                    console.log(data, 'error')
                }
            })
            .catch(error => {
                console.log(error, 'error')
            })
    }

    const callRemoveItemMutation = async (variables: CartRemoveItemMutationVariables) => {
        await removeItemMutation({ ...variables })
            .then(({ data }) => {
                if (data?.cartLinesRemove?.cart) {
                    setCart(data.cartLinesRemove.cart as Cart)
                    setLoading(false)
                } else {
                    console.log(data, 'error')
                }
            })
            .catch(error => {
                console.log(error, 'error')
            })
    }

    const getAutoAddToCartParams = (): { variantId: string; quantity: string } => {
        const urlParams = new URLSearchParams(window.location.search)
        const variantId = urlParams.get('variantId')
        const quantity = urlParams.get('quantity')
        let cartParams = { variantId: '', quantity: '' }
        if (variantId && !autoAddedItem) {
            setAutoAddedItem(true)
            cartParams = {
                variantId: variantId,
                quantity: quantity || '1',
            }
        }
        return cartParams
    }

    const getDiscountParam = (): ActiveDiscountCode | null => {
        const urlParams = new URLSearchParams(window.location.search)
        const referralCode = urlParams.get('referral-code')
        const couponCode = urlParams.get('coupon-code')
        const discountFromStorage = window.sessionStorage.getItem('discount_code')

        let urlDiscount: ActiveDiscountCode | null = null
        if (referralCode) {
            urlDiscount = {
                type: 'referral-code',
                code: referralCode,
            }
        } else if (couponCode) {
            if (couponCode !== 'GETKEYLESS40')
                urlDiscount = {
                    type: 'coupon-code',
                    code: couponCode,
                }
        } else if (discountFromStorage) {
            return JSON.parse(discountFromStorage)
        }

        return urlDiscount
    }

    const maybeAutoAddToCart = async (cartId: string) => {
        const { variantId, quantity } = getAutoAddToCartParams()
        if (variantId !== '') {
            await addVariantToCart(variantId, parseInt(quantity))
        }
    }

    const maybeAutoApplyDiscount = async (cartId: string) => {
        const discountParam = getDiscountParam()
        if (discountParam) {
            const result = await addDiscountCodeToCart(cartId, discountParam.code)
            if (result?.discountCodes?.length /*&& result?.discountCodes?.[0]?.applicable*/) {
                // Disregard applicable check here, maybe later it can become applicable when contents change
                window.sessionStorage.setItem('discount_code', JSON.stringify(discountParam))
                setShowDiscountModal(true)
                discountParam && setActiveDiscountCode(discountParam)
            } else {
                discountParam && setErrorCoupon(discountParam)
                window.sessionStorage.removeItem('discount_code')
            }
        }
    }
    const initializeCheckout = useCallback(async () => {
        if (cartData?.cart) {
            setCartItem(cartData.cart)
            await maybeAutoApplyDiscount(cartData.cart.id)
        } else {
            createCartMutation({ cartInput: { lines: [] } })
                .then(async ({ data }) => {
                    if (data?.cartCreate?.cart) {
                        setCartItem(data.cartCreate.cart)
                        await maybeAutoApplyDiscount(data.cartCreate.cart.id)
                    } else {
                        console.log(data, 'error')
                    }
                })
                .catch(error => {
                    console.log(error, 'error')
                })
        }
    }, [cartData])

    const setCartItem = (cart: any) => {
        if (isBrowser) {
            localStorage.setItem(SHOPIFY_CART_ID, cart.id)
        }

        setCart(cart)
    }

    const allBundlesData = useAllBundles()
    useEffect(() => {
        if (isOpen && cart) {
            trackViewCart(cart)
        }
    }, [isOpen])

    useEffect(() => {
        if (allBundlesData?.bundles) {
            setAllBundles(allBundlesData.bundles)
            if (siteBundles) {
                const activeUpsellBundleIds = siteBundles.map(bundle => bundle.value)
                setUpsellBundles(
                    allBundlesData.bundles.filter(bundle => activeUpsellBundleIds.includes(bundle.bundle.id)),
                )
            }
        }
    }, [siteBundles, allBundlesData])

    const [checkoutData, setCheckoutData] = useState<any>()
    const [autoAddedItem, setAutoAddedItem] = useState(false)

    useEffect(() => {
        ;(async () => {
            if (!cart?.discountCodes?.[0]?.applicable) {
                // If discount is not applicable check for bulk discounts
                const newCartData = {
                    shop: process.env.GATSBY_SHOPIFY_BASE_STORE_URL,
                    items: cart?.lines?.edges?.map(item => ({
                        quantity: item.node.quantity,
                        variant_id: parseInt(fromStorefrontId(item.node.merchandise.id)),
                        final_line_price: item.node.quantity * parseInt(item.node.merchandise.price.amount) * 100,
                    })),
                }

                if (JSON.stringify(newCartData) !== JSON.stringify(checkoutData)) {
                    setCheckoutData(newCartData)

                    const data = newCartData?.items?.length
                        ? await fetch('https://bundle.revy.io/api/cart-page', {
                              method: 'POST',
                              headers: {
                                  'User-Agent': 'Strapi CMS',
                                  'Content-Type': 'application/json',
                              },
                              body: JSON.stringify(newCartData),
                          }).then(response => response.json())
                        : null

                    setDiscountTotals(data?.totals ? data.totals : null)
                }
            }
        })()
        if (cart?.id) {
            maybeAutoAddToCart(cart.id)
        }
    }, [cart])

    const addVariantToCart = async (variantId: string, quantity: number) => {
        setCartError(null)
        if (cart) {
            setLoading(true)
            setIsOpen(true)
            setHideSnackbar(true)

            const variables: AddToCartMutationVariables = {
                lines: [{ merchandiseId: toStorefrontId(variantId), quantity: quantity }],
                cartId: cart.id,
            }

            await callAddToCart(variables)
        }
    }

    const addVariantsToCart = async (lineItemsToUpdate: LineItemsProps[]) => {
        setCartError(null)

        if (cart) {
            setLoading(true)
            setIsOpen(true)
            setHideSnackbar(true)

            const variables: AddToCartMutationVariables = {
                lines: lineItemsToUpdate.map(lineItemToUpdate => {
                    return {
                        merchandiseId: lineItemToUpdate.variantId,
                        quantity: lineItemToUpdate.quantity,
                    } as CartLineInput
                }),
                cartId: cart.id,
            }

            await callAddToCart(variables)
        }
    }

    const removeLineItem = async (cartId: string, linesId: string) => {
        setCartError(null)

        if (cart) {
            setLoading(true)

            const lineItemToRemove = cart.lines.edges.find(item => item.node.id === linesId)

            const variables = {
                lineIds: linesId,
                cartId: cartId,
            }

            await callRemoveItemMutation(variables)
            trackRemoveFromCart(cart, lineItemToRemove)
        }
    }

    const updateLineItem = async (cartId: string, lineItemID: string, quantity: number, addToCart: boolean) => {
        setCartError(null)

        if (quantity === 0) {
            removeLineItem(cartId, lineItemID)
        } else {
            setLoading(true)

            const variables = {
                lines: [{ id: lineItemID, quantity: quantity }],
                cartId: cartId,
            }

            await callUpdateCartMutation(variables, addToCart)
        }
    }

    const addDiscountCodeToCart = async (cartID: string, discountCodeToAdd: string) => {
        return await addDiscountMutation({ cartId: cartID, discountCodes: discountCodeToAdd })
            .then(({ data }) => {
                if (data?.cartDiscountCodesUpdate?.cart) {
                    setCartItem(data.cartDiscountCodesUpdate.cart)
                    return data.cartDiscountCodesUpdate.cart
                } else {
                    console.log(data, 'error')
                }
            })
            .catch(error => {
                console.log(error, 'error')
            })
    }

    const feedBackWidget = isBrowser ? document.getElementsByClassName('_hj_feedback_container')?.[0] : null
    const sizeWidget = isBrowser ? document.querySelector("[class*='videoask-embed']") : undefined
    const futyContainer = isBrowser ? document.getElementById('futy-container') : null
    const chatbotContainer = isBrowser ? document.getElementById('chatbase-bubble-button') : null
    const onOpenMenu = () => {
        setHideSnackbar(true)
        setIsMenuOpen(true)
    }

    const onOpenOverlay = () => {
        setIsOverlayOpen(true)
    }

    const onCLoseOverlay = () => {
        setIsOverlayOpen(false)
    }

    const onCloseMenu = () => {
        setHideSnackbar(false)
        setIsMenuOpen(false)
    }
    const onClose = () => {
        setIsOpen(false)
        setHideSnackbar(false)
    }

    const onOpen = () => {
        setIsOpen(true)
        setHideSnackbar(true)
    }

    const hideWidgets = (widgets: (HTMLElement | Element | null | undefined)[]) => {
        widgets.map(widget => {
            if (widget) {
                widget.classList.add('hideWidget')
            }
        })
    }

    const showWidgets = (widgets: (HTMLElement | Element | null | undefined)[]) => {
        widgets.map(widget => {
            if (widget) {
                widget.classList.remove('hideWidget')
            }
        })
    }

    useEffect(() => {
        if (isMobile) {
            if (isOpen || isOverlayOpen) {
                hideWidgets([sizeWidget, chatbotContainer, futyContainer, feedBackWidget])
            }
        } else {
            if (isOpen) {
                hideWidgets([futyContainer, chatbotContainer, feedBackWidget])
            }
            if (isOverlayOpen) {
                hideWidgets([feedBackWidget])
            }
        }

        if (isMenuOpen) {
            hideWidgets([sizeWidget, futyContainer, chatbotContainer, feedBackWidget])
        }
        if (!isOpen && !isMenuOpen && !isOverlayOpen) {
            showWidgets([sizeWidget, futyContainer, chatbotContainer, feedBackWidget])
        }
    }, [isOpen, isMenuOpen, isOverlayOpen])

    const startCheckout = async () => {
        setLoading(true)
        const fetchCheckoutCreateResult = createCheckout({
            lineItems: cart?.lines?.edges
                ?.filter(item => item.node.quantity > 0)
                ?.map(item => ({
                    quantity: item.node.quantity,
                    variantId: item.node.merchandise.id,
                })),
        })
            .then(({ data }) => {
                if (data?.checkoutCreate?.checkout?.lineItems?.edges) {
                    return data.checkoutCreate.checkout
                } else {
                    setCartError(messages.genericError.toString())
                }
            })
            .then(async checkoutResult => {
                const discountCode = isBrowser && window.sessionStorage.getItem('discount_code')
                if (discountCode && checkoutResult?.id) {
                    return await addDiscountToCheckout({
                        discountCode: discountCode,
                        checkoutId: checkoutResult.id,
                    })
                        .then(({ data }) => {
                            if (data?.checkoutDiscountCodeApplyV2?.checkout) {
                                return data.checkoutDiscountCodeApplyV2.checkout
                            } else {
                                setCartError(messages.genericError.toString())
                            }
                        })
                        .catch(error => {
                            setCartError(messages.genericError.toString())
                        })
                } else {
                    return checkoutResult
                }
            })
            .catch(error => {
                setCartError(messages.genericError.toString())
            })

        const fetchDiscountData = activeDiscountCode
            ? { discount_code: activeDiscountCode.code }
            : await fetch('https://bundle.revy.io/api/discount', {
                  method: 'POST',
                  body: JSON.stringify({
                      shop: process.env.GATSBY_SHOPIFY_BASE_STORE_URL,
                      items: cart?.lines?.edges
                          ?.filter(item => item.node.quantity > 0)
                          ?.map(item => ({
                              quantity: item.node.quantity,
                              variant_id: parseInt(fromStorefrontId(item.node.merchandise.id)),
                              final_line_price: item.node.quantity * parseInt(item.node.merchandise.price.amount) * 100,
                          })),
                  }),
                  headers: {
                      'User-Agent': 'Strapi CMS',
                      'Content-Type': 'application/json',
                  },
              }).then(response => response.json())
        const [discountData, checkoutCreateResult] = await Promise.all([fetchDiscountData, fetchCheckoutCreateResult])

        if (checkoutCreateResult) {
            await addDiscountToCheckout({
                discountCode: discountData.discount_code,
                checkoutId: checkoutCreateResult.id,
            })
            const checkoutUrl = checkoutCreateResult.webUrl.replace(
                process.env.GATSBY_SHOPIFY_BASE_STORE_URL,
                process.env.GATSBY_SHOPIFY_STORE_URL,
            )
            const url = new URL(checkoutUrl)
            locale && url.searchParams.set('locale', locale)
            window.location.href = checkoutUrl
        } else {
            setLoading(false)
        }
    }

    return (
        <StoreContext.Provider
            value={{
                ...defaultValues,
                addVariantToCart,
                removeLineItem,
                updateLineItem,
                onClose,
                onOpen,
                cart,
                isOpen,
                loading,
                didJustAddToCart,
                addVariantsToCart,
                startCheckout,
                discountTotals,
                upsellBundles,
                setSiteBundles,
                setHideSnackbar,
                hideSnackbar,
                setShowDiscountModal,
                showDiscountModal,
                setLocale,
                activeDiscountCode,
                allBundles,
                isMenuOpen,
                onOpenOverlay,
                isOverlayOpen,
                onCLoseOverlay,
                onOpenMenu,
                errorCoupon,
                onCloseMenu,
                addDiscountCodeToCart,
                cartError,
            }}
        >
            {children}
        </StoreContext.Provider>
    )
}
