import {removeAuthToken} from "@utils/auth";
import {navigate} from "gatsby";
import {getI18nPaths} from "../i18n/paths";
import {useIntl} from "react-intl";

const useLogout = () => {
    const {locale} = useIntl()
    const localePaths = getI18nPaths(locale)
    removeAuthToken()
    navigate(localePaths.login)
}

export default useLogout