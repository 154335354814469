const messages = {
    tokenExpired: 'Token verlopen',
    tokenExpiredMessage: 'Het token voor het opnieuw instellen van het wachtwoord is verlopen.',
    langSwitchLabel: 'Selecteer een taal',
    productsUrl: 'producten',
    in_stock: 'Op voorraad',
    low_stock: 'Beperkt op voorraad',
    selectLabel: 'Selecteer een',
    myAccount: 'Mijn account',
    addToCart: 'In winkelmandje',
    newsletterSignup: 'Schrijf je in voor onze nieuwsbrief',
    placeOrder: 'Plaats je bestelling',
    createAnAccount: 'Account aanmaken',
    createAccount: 'Account aanmaken',
    register: 'Registreren',
    forgotMyPassword: 'Wachtwoord vergeten',
    resetMyPassword: 'Wachtwoord resetten',
    businessPortalDescription: 'Beheer eenvoudig uw bedrijf, waar u ook bent.',
    repeatResetMyPassword: 'Herhaal je nieuwe password',
    login: 'Login',
    chooseAPassword: 'Kies een wachtwoord',
    password: 'Wachtwoord',
    emailAddress: 'E-mailadres',
    genericError: 'Er ging iets mis, probeer het opnieuw.',
    newsletterSignUpConfirmation: 'Bedankt voor je aanmelding. Controleer je e-mailadres om te bevestigen.',
    dashboard: 'Dashboard',
    orders: 'Bestellingen',
    followVideo: 'Volg onze stapsgewijze',
    instructionVideo: 'maat configurator',
    from: 'Vanaf',
    needHelp: 'Hulp nodig?',
    myProfile: 'Mijn profiel',
    myAddress: 'Mijn adres',
    myOrders: 'Mijn bestellingen',
    editMyProfile: 'Profiel bewerken',
    saveProfileDetails: 'Profielgegevens opslaan',
    firstName: 'Voornaam',
    optional: 'optioneel',
    lastName: 'Achternaam',
    phone: 'Telefoonnummer',
    emailUpdates: 'E-mail updates',
    emailUpdatesLabel: 'Ik blijf graag op de hoogte over Bold Smart Lock via e-mail',
    address1: 'Straat en huisnummer',
    address2: 'Apartementnr. etc.',
    city: 'Stad',
    zip: 'Postcode',
    countryRegion: 'Land/regio',
    logOut: 'Log uit',
    forgotPasswordDescription: 'Vul je e-mailadres in, dan ontvang je daar een wachtwoord reset link op.',
    selectAPage: 'Selecteer een pagina',
    returns: 'Retouren',
    support: 'Support',
    referral: 'Referral',
    writeAReview: 'Schrijf een recensie',
    status: 'Status',
    orderDate: 'Besteldatum',
    orderId: 'Bestellingsnummer',
    shippingAddress: 'Afleveradres',
    invoiceAddress: 'Factuuradres',
    paymentMethod: 'Betalingsmethode',
    trackAndTrace: 'Track en Trace',
    reviewThisOrder: 'Beoordeel deze bestelling',
    downloadInvoice: 'Download factuur',
    contactSupport: 'Contact support',
    subtotal: 'Subtotaal',
    incVAT: 'Inc. BTW',
    expandThisOrder: 'Bestelling uitklappen',
    loginDescription: 'Omdat het leven te kort is om je druk te maken over alles wat met sleutels te maken heeft.',
    registerDescription: 'Zodra u uw eerste bestelling heeft geplaatst, vindt u hier alle informatie.',
    profileUpdated: 'Je profiel is bijgewerkt.',
    fullLangName: 'Nederlands',
    welcome: 'Welkom',
    unsureDescription: 'Wil je iets delen of vragen? We helpen je graag. Altijd en overal.',
    stillUnsure: 'Nog onzeker?',
    lockSizeDescription:
        'Zorg ervoor dat je de Bold Smart Lock kiest die perfect past. Er zijn 8 verschillende maten beschikbaar en welke je nodig hebt, hangt af van je deur. Meet je huidige cilinder, match de maat in de tabel en kies je slot.',
    unsureAboutTheSize: 'Onzeker over de maat?',
    pageNotFound: 'Pagina niet gevonden',
    pageNotFoundBody: 'De pagina waarnaar je zoekt is niet gevonden.<br />We helpen je graag verder.',
    viewOurFaq: 'Bekijk onze FAQ',
    viewCart: 'Bekijk winkelwagen',
    downloadAppStore: 'Download de app in de app store van jouw telefoon',
    navigateToHomepage: 'Navigeer naar de homepage',
    reduceQuantityByOne: 'Verminder hoeveelheid met een',
    addQuantityByOne: 'Verhoog hoeveelheid met een',
    viewProduct: 'Bekijk product',
    addProductToBundle: 'Voeg product aan bundel toe',
    viewPreviousPhoto: 'Bekijk vorige foto',
    viewNextPhoto: 'Bekijk volgende foto',
    discount: 'korting',
    discountLabel: 'Pak :discount% korting',
    phonePlaceholder: '+31',
    onSale: 'On sale',
    emptyCart: 'Je winkelmand is nog leeg',
    emptyCartDescription:
        'Het lijkt erop dat je nog niets in je winkelmand geplaatst hebt. Vind alles wat je nodig hebt in onze shop en voeg het toe aan je winkelmand.',
    emptyCartButton: 'Ontdek onze producten',
    passwordProtectTitle: 'Wachtwoord',
    passwordProtectDescription: 'Deze pagina is beschermd met een wachtwoord',
    passwordProtectLabel: 'Wachtwoord',
    passwordProtectPlaceholder: 'Vul het ontvangen wachtwoord in',
    passwordProtect: 'Ga verder',
    upsellDescription: 'Deel het. Waar dan ook, wanneer dan ook met extra korting.',
    combideal: 'Ga voor de combideal',
    shoppingcartHeader: 'Mijn winkelmandje',
    repeatActivatePassword: 'Herhaal je wachtwoord',
    activateAccount: 'Activeer je account',
    activateMyAccount: 'Activeer account',
    required: 'Dit veld is verplicht.',
    fieldNameRequired: 'Het veld :fieldName is verplicht.',
    emailInvalid: 'Vul een geldig e-mailadres in. (jan@janssen.nl)',
    attachment_size: 'Het bestand mag maximaal 10 MB zijn.',
    attachment_extension:
        'Het gekozen bestand kan niet geüpload worden. Alleen bestanden met de volgende extensies zijn toegestaan: jpg, jpeg, png, pdf',
    conditionsRequired: 'U dient akkoord te gaan met onze algemene voorwaarden.',
    maxChars: 'Vul maximaal :max tekens in.',
    minChars: 'Vul minimaal :min tekens in.',
    exactChars: 'Vul :minMax tekens in.',
    equalToPassword: 'Wachtwoorden komen niet overeen.',
    equalToEmail: 'E-mailadressen komen niet overeen.',
    digitsOnly: 'Dit veld mag alleen cijfers bevatten.',
    defaultOption: 'Selecteer een optie.',
    continueShopping: 'Verder shoppen',
    couponError: 'Coupon :code is ongeldig of verlopen',
    couponSuccess: ':discount korting via code :code wordt automatisch toegevoegd bij afrekenen',
    buyBundle: 'Koop bundel',
    mostCommon: 'Meest gekozen',
    selectA: 'Selecteer :size',
    Size: 'Maat',
    Color: 'Kleur',
    buyMorePayLess: 'Koop er meerdere, betaal minder.',
    automaticDiscount: 'Korting wordt automatisch toegepast bij checkout.',
    noCombinedDiscounts: 'Kortingen kunnen niet gecombineerd worden met aanbiedingen voor andere producten.',
    loginWarningText: 'Let op! Je online account is niet gelinkt aan je Bold-App.',
    no_stock: 'Niet op voorraad',
    trustpilotLocale: 'nl-NL',
    howWeCollectOurReviews: 'Hoe wij onze reviews verzamelen',
    reviewCollectionDetails: `<p>
                        De gepubliceerde beoordelingen van Bold worden verzameld en gecontroleerd via de onafhankelijke partij
                        <a
                           style='text-decoration: underline'
                            href='https://nl.trustpilot.com/review/boldsmartlock.com'
                            target='_blank'
                        >Trustpilot</a>. De reviewers zijn echte klanten die hun oprechte ervaring met de producten en diensten van Bold delen.
Om meer te lezen over het verzamelen van beoordelingen en hoe Trustpilot de integriteit van hun platform waarborgt, klik hier:
                        <a
                            target='_blank'
                           style='text-decoration: underline'
                            href='https://nl.legal.trustpilot.com/for-reviewers/guidelines-for-reviewers'
                        >https://nl.legal.trustpilot.com/for-reviewers/guidelines-for-reviewers</a>.
                    </p>`,
    mobileSize: 'size',
    color: { desktop: 'kleur', mobile: 'kleur' },
    size: { desktop: 'maat', mobile: 'maat' },
    amount: { desktop: 'aantal', mobile: 'aantal' },
    Amount: 'Aantal',
    phoneLandcode: 'Vul een geldig telefoonnummer in en begin met de landcode. (Bijv. +31)',
    startingFrom: 'Beginnend vanaf',
    share: 'Deel deze pagina',
    newsletterSignupPlaceHolder: 'Jouw email-adres',
    articlesUrl: 'blog',
    authorUrl: 'auteurs',
    categoryUrl: 'categorieen',
    noArticles: 'Er zijn nog geen artikelen beschikbaar',
    readMore: 'Lees meer',
    findYourSize: 'Ontdek welke maat je nodig hebt',
    visitMeasurePage: 'Maattabel',
    preOrder: 'Pre-order',
    notifyMe: 'Hou mij op de hoogte',
    readLess: 'Lees minder',
    notifyMeContent: `<h2>Wil je op de hoogte gehouden worden?</h2><p>Laat je e-mailadres achter en we sturen je een mail wanneer het product weer op voorraad is.</p>`,
    notifyMeThankYou: `<p>Super! Je ontvangt een mail zodra het product weer op voorraad is.</p>`,
    latestOnThisTheme: 'Laatste nieuws in deze categorie',
    generalTermsLink: '/nl/algemene-voorwaarden',
    generalTerms: 'algemene voorwaarden',
    acceptTerms: `<p>Door verder te gaan ga je akkoord met de [termsLink] en  heb je de bestelling en gegevens gecontroleerd.</p>`,
    pleaseAcceptTerms: 'U dient akkoord te gaan met de bestelbevestiging',
    inThisArticle: 'In dit artikel',
    Black: 'Zwart',
    Silver: 'Zilver',
    Brass: 'Messing',
    Copper: 'Koper',
    Orange: 'Oranje',
    Green: 'Groen',
    Blue: 'Blauw',
    Pink: 'Roze',
    'Light Blue': 'Lichtblauw',
    'Light Grey': 'Lichtgrijs',
    'Light Green': 'Lichtgroen',
    sx33CorrectSize: 'Bekijk hier of de SX33 de juist maat is voor jou.',
    onlyInSx33: 'Let op: De Bold Smart Cylinder (Limited Edition) is alleen verkrijgbaar als SX-33.',
    readTime: (readTime: string) => `leestijd: ${readTime} min`,
    moreOnThisSubject: 'Meer over dit onderwerp',
    exploreThis: 'Ontdek',
}

export default messages
