const messages = {
    tokenExpired: 'Token abgelaufen',
    tokenExpiredMessage: 'Das Passwort-Reset-Token ist abgelaufen.',
    langSwitchLabel: 'Eine Sprache wählen',
    productsUrl: 'produkte',
    in_stock: 'Auf Lager',
    low_stock: 'Begrenzter Vorrat',
    selectLabel: 'Wähle',
    size: { desktop: 'eine größe', mobile: 'größe' },
    color: { desktop: 'eine farbe', mobile: 'farbe' },
    myAccount: 'Mein Konto',
    addToCart: 'In den Warenkorb legen',
    newsletterSignup: 'Melde dich für unseren Newsletter an',
    placeOrder: 'Bestellung aufgeben',
    createAnAccount: 'Ein Konto erstellen',
    createAccount: 'Benutzerkonto erstellen',
    register: 'Registrieren',
    forgotMyPassword: 'Ich habe mein Passwort vergessen',
    resetMyPassword: 'Mein Passwort zurücksetzen',
    repeatResetMyPassword: 'Wiederhole dein neues Passwort',
    businessPortalDescription: 'Steuern Sie Ihr Unternehmen ganz einfach von jedem Ort aus.',
    login: 'Anmeldung',
    chooseAPassword: 'Wähle ein Passwort',
    password: 'Passwort',
    emailAddress: 'E-Mail-Addresse',
    genericError: 'Etwas ist schief gelaufen',
    newsletterSignUpConfirmation:
        'Vielen Dank, dass du dich für unseren Newsletter angemeldet hast. Bitte kontrolliere zur Bestätigung deine E-Mails.',
    dashboard: 'Armaturenbrett',
    orders: 'Aufträge',
    from: 'Ab',
    myProfile: 'Mein Profil',
    myAddress: 'Meine Adresse',
    myOrders: 'Meine Bestellungen',
    editMyProfile: 'Mein Profil bearbeiten',
    saveProfileDetails: 'Speicher meine Profildaten',
    firstName: 'Vorname',
    optional: 'Optional',
    lastName: 'Nachname',
    phone: 'Telefonnummer',
    emailUpdates: 'Email Updates',
    emailUpdatesLabel: 'Ich möchte Bold Smart Lock-Updates per E-Mail erhalten',
    address1: 'Straße und Hausnummer',
    address2: 'Apartment, etc.',
    city: 'Stadt',
    zip: 'Postleitzahl',
    countryRegion: 'Land / Region',
    logOut: 'Ausloggen',
    forgotPasswordDescription:
        'Gebe deine E-Mail-Adresse ein, damit wir dir einen Link senden können, in dem du dein Passwort zurücksetzen kannst.',
    selectAPage: 'Seite auswählen',
    returns: 'Rücksendungen',
    support: 'Kundenservice',
    referral: 'Empfehlungen',
    writeAReview: 'Eine Rezension schreiben',
    status: 'Status',
    orderDate: 'Bestelldatum',
    orderId: 'Bestellnummer',
    shippingAddress: 'Lieferanschrift',
    invoiceAddress: 'Rechnungsanschrift',
    paymentMethod: 'Bezahlmethode',
    trackAndTrace: 'Sendungsverfolgung',
    reviewThisOrder: 'Überprüfe die Bestellung',
    downloadInvoice: 'Rechnung herunterladen',
    contactSupport: 'Kontaktiere unseren Kundenservice',
    subtotal: 'Zwischensumme',
    incVAT: 'Inkl. MwSt',
    expandThisOrder: 'Die Bestellung erweitern',
    loginDescription: 'Weil das Leben zu kurz ist, um sich über Schlüssel Sorgen zu machen.',
    registerDescription: 'Sobald du deine erste Bestellung aufgegeben hast, kannst du hier alle Informationen finden.',
    profileUpdated: 'Dein Profil wurde aktualisiert.',
    fullLangName: 'Englisch',
    welcome: 'Willkommen',
    unsureDescription: 'Hast du Fragen oder Anmerkungen? Wir helfen dir gerne jederzeit weiter.',
    stillUnsure: 'Immer noch nicht ganz sicher?',
    lockSizeDescription: 'Stelle sicher, dass du das Bold Smart Lock wählst, welches perfekt passt.',
    unsureAboutTheSize: 'Unsicher über die Größe?',
    pageNotFound: 'Seite nicht gefunden',
    pageNotFoundBody: 'Die von dir gesuchte Seite kann nicht gefunden werden. <br /> Wir helfen dir gerne weiter.',
    viewOurFaq: 'Schau dir unsere FAQ an',
    viewCart: 'Warenkorb ansehen',
    downloadAppStore: 'Lade die App im AppStore deines Mobiltelefons herunter',
    navigateToHomepage: 'Zur Homepage navigieren',
    reduceQuantityByOne: 'Anzahl um 1 reduzieren',
    addQuantityByOne: 'Anzahl um 1 erweitern',
    viewProduct: 'Produkt anzeigen',
    addProductToBundle: 'Produkt zum Bündel hinzufügen',
    viewPreviousPhoto: 'Vorheriges Foto anzeigen.',
    viewNextPhoto: 'Nächstes Foto anzeigen.',
    discountLabel: 'Hole dir einen :discount% Rabatt',
    phonePlaceholder: '+32',
    upsellDescription: 'Einfach teilen. Egal wann.',
    combideal: 'Wähle den Combi-Deal',
    shoppingcartHeader: 'Mein Warenkorb',
    repeatActivatePassword: 'Wiederhole dein Passwort',
    activateAccount: 'Deinen Account aktivieren',
    activateMyAccount: 'Konto aktivieren',
    discount: 'Rabatt',
    onSale: 'Im Angebot',
    emptyCart: 'Ihr Warenkorb ist leer',
    emptyCartDescription:
        'Anscheinend haben Sie noch nichts Fett in Ihren Warenkorb gelegt. Schauen Sie sich unsere Produkte an, um das Gesuchte zu finden und es in Ihren Warenkorb zu legen.',
    emptyCartButton: 'Entdecken Sie unsere Produkte',
    passwordProtectTitle: 'Passwortgeschützt',
    passwordProtectDescription: 'Diese Seite ist mit einem Passwort geschützt.',
    passwordProtectLabel: 'Passwort',
    passwordProtectPlaceholder: 'Geben Sie das erhaltene Passwort ein',
    passwordProtect: 'Passwort senden',
    required: 'Dieses Feld wird benötigt.',
    fieldNameRequired: 'Das Feld :fieldName ist erforderlich.',
    emailInvalid: 'Geben Sie eine gültige E-Mail-Adresse ein. (anna@schmidt.de)',
    attachment_size: 'Die Dateigröße muss kleiner als 10 MB sein.',
    attachment_extension:
        'Die ausgewählte Datei kann nicht hochgeladen werden. Es sind nur Dateien mit den folgenden Erweiterungen erlaubt: jpg, jpeg, png, pdf',
    conditionsRequired: 'Sie müssen unseren Allgemeinen Geschäftsbedingungen zustimmen.',
    maxChars: 'Geben Sie maximal :max Zeichen ein.',
    minChars: 'Geben Sie maximal :min Zeichen ein.',
    exactChars: 'Geben Sie :minMax Zeichen ein.',
    equalToPassword: 'Passwörter stimmen nicht überein.',
    equalToEmail: 'E-Mail-Adressen stimmen nicht überein.',
    digitsOnly: 'Dieses Feld darf nur Ziffern enthalten.',
    defaultOption: 'Wähle eine Option.',
    continueShopping: 'Zum Einkaufen',
    couponError: 'Gutschein :code ist ungültig oder abgelaufen',
    couponSuccess: ':discount von Code :code wird automatisch Ihrem Warenkorb hinzugefügt',
    buyBundle: 'Bundle kaufen',
    mostCommon: 'Am gebräuchlichsten',
    selectA: 'Wähle :size',
    Size: 'Größe',
    Color: 'Farbe',
    automaticDiscount: 'Rabatt wird automatisch im Checkout abgerechnet.',
    noCombinedDiscounts: 'Rabatte können nicht mit anderen Angeboten für andere Produkte kombiniert werden.',
    buyMorePayLess: 'Kaufe mehr, zahle weniger.',
    loginWarningText: 'Bitte beachten: Diese Zugangsdaten beziehen sich nicht auf die Bold-App',
    no_stock: 'Out of stock',
    trustpilotLocale: 'de-DE',
    howWeCollectOurReviews: 'Wie wir unsere Bewertungen sammeln',
    reviewCollectionDetails: `<p>
                        Die von Bold veröffentlichten Bewertungen werden über den unabhängigen Drittanbieter
                        <a
                         style='text-decoration: underline'
                            href='https://de.trustpilot.com/review/boldsmartlock.com'
                            target='_blank'
                        >Trustpilot</a> gesammelt und formell überprüft. Bei den Rezensenten handelt es sich um tatsächliche Kunden, die ihre echten Erfahrungen mit den Produkten und Dienstleistungen von Bold teilen.
Um mehr über den Prozess der Bewertungssammlung zu erfahren und darüber, wie Trustpilot die Integrität seiner Plattform sicherstellt, klicke hier:
                        <a
                            target='_blank'
                           style='text-decoration: underline'
                            href='https://de.legal.trustpilot.com/for-reviewers/guidelines-for-reviewers'
                        >https://de.legal.trustpilot.com/for-reviewers/guidelines-for-reviewers</a>.
                    </p>`,
    Amount: 'Anzahl',
    mobileSize: 'size',
    amount: { desktop: 'anzahl', mobile: 'anzahl' },
    phoneLandcode: 'Fill in a valid phonenumber, starting with the country code. (i.e. +31)',
    startingFrom: 'Ab',
    share: 'Teile diese Seite',
    newsletterSignupPlaceHolder: 'Ihre E-Mail-Addresse',
    articlesUrl: 'bloggen',
    authorUrl: 'autoren',
    categoryUrl: 'kategorien',
    noArticles: 'Es sind noch keine Artikel verfügbar',
    readMore: 'Weiterlesen',
    findYourSize: 'Finden Sie heraus, welche Größe Sie benötigen',
    visitMeasurePage: 'Größentabelle',
    preOrder: 'Vorbestellung',
    notifyMe: 'Benachrichtigen Sie mich',
    readLess: 'Lese weniger',
    notifyMeContent: `<h2>Wünschen Sie eine Benachrichtigung?</h2><p>Hinterlegen Sie Ihre E-Mail-Adresse und Sie erhalten von uns eine E-Mail, sobald das Produkt wieder vorrätig ist.</p>`,
    notifyMeThankYou: `<p>Großartig! Wir senden Ihnen eine E-Mail, sobald das Produkt wieder vorrätig ist.</p>`,
    latestOnThisTheme: 'Latest on this theme',
    generalTermsLink: '/de/geschaeftsbedingungen',
    generalTerms: 'algemene voorwaarden',
    acceptTerms: `<p>Door verder te gaan ga je akkoord met de [termsLink] en  heb je de bestelling en gegevens gecontroleerd.</p>`,
    pleaseAcceptTerms: 'U dient akkoord te gaan met de bestelbevestiging',
    inThisArticle: 'In diesem Artikel',
    Black: 'Schwarz',
    Silver: 'Silber',
    Brass: 'Messing',
    Copper: 'Kupfer',
    Orange: 'Orange',
    Green: 'Grün',
    Blue: 'Blau',
    Pink: 'Rosa',
    'Light Blue': 'Hellblau',
    'Light Grey': 'Hellgrau',
    'Light Green': 'Hellgrün',
    readTime: (readTime: string) => `${readTime} Minute Lesezeit`,
    sx33CorrectSize: 'Finden Sie heraus, ob SX-33 die richtige Größe für Sie ist.',
    needHelp: 'Brauchen Sie Hilfe?',
    followVideo: 'Folgen Sie unserem Schritt-für-Schritt',
    instructionVideo: 'Größenkonfigurator',
    onlyInSx33:
        'Bitte beachten Sie, dass der Bold Smart Cylinder (Limited Edition) nur in der Größe SX-33 erhältlich ist.',
    moreOnThisSubject: 'Mehr über dieses Thema',
    exploreThis: 'Dies erforschen',
}

export default messages
