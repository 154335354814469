import * as React from "react"
import nl from "./nl"
import en from "./en"
import de from "./de"
import {i18nPathsProps, Locales} from "../types"

export const getI18nPaths = (langKey: Locales): i18nPathsProps => {
    if (langKey === "nl") {
        return nl
    } else if (langKey === "de") {
        return de
    }

    return en
}