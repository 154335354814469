import {Theme} from "@emotion/react"

const bodyFont = "ToucheW03, sans-serif"

const breakpointValues = {
    xs: 414,
    sm: 544,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1370,
}

const maxWidthValues = {
    xs: 0,
    sm: 606,
    md: 750,
    lg: 970,
    xl: 1170,
    xxl: 1660,
}

export const theme: Theme = {
    colors: {
        text: "#232323",
        background: "#fff",
        primary: "#232323",
        secondary: "#fff",
        tertiary: "#ff6620",
        error: "#f00",
        gray: "#dddddf",
        grayBackground: "#f2f3f7"
    },
    fonts: {
        body: bodyFont,
        heading: bodyFont,
        pxToRem: (value: number) => `${value / 16}rem`,
    },
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
    fontWeights: {
        thin: 300,
        body: 400,
        heading: 700,
        medium: 500,
        semibold: 600,
        bold: 700,
    },
    lineHeights: {
        body: 1.375,
        heading: 1.25,
    },
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    sizes: {
        avatar: 48,
    },
    radii: {
        border: [0, 4, 8, 16, 32],
    },
    shadows: {
        card: "0 0 4px rgba(0, 0, 0, .125)",
    },
    text: {
        heading: {
            fontFamily: "heading",
            lineHeight: "heading",
            fontWeight: "heading",
        },
        display: {
            fontFamily: "heading",
            fontWeight: "heading",
            lineHeight: "heading",
            fontSize: [5, 6, 7],
        },
    },
    breakpointSizes: ["xs", "sm", "md", "lg", "xl", "xxl"],
    breakpoints: Object.values(breakpointValues).map(item => `${item}px`),
    maxWidths: Object.values(maxWidthValues),
    breakpoint: {
        up: (key: keyof typeof breakpointValues) =>
            `@media (min-width:${breakpointValues[key]}px)`,
        down: (key: keyof typeof breakpointValues) =>
            `@media (max-width:${breakpointValues[key] - 5 / 100}px)`,
        between: (
            start: keyof typeof breakpointValues,
            end: keyof typeof breakpointValues
        ) =>
            `@media (min-width:${breakpointValues[start]}px) and (max-width:${
                breakpointValues[end] - 5 / 100
            }px)`,
        only: (key: keyof typeof breakpointValues) => {
            const keys = Object.keys(
                breakpointValues
            ) as (keyof typeof breakpointValues)[]
            if (keys.indexOf(key) + 1 < keys.length) {
                return `@media (min-width:${
                    breakpointValues[key]
                }px) and (max-width:${
                    breakpointValues[keys[keys.indexOf(key) + 1]] - 5 / 100
                }px)`
            }

            return `@media (min-width:${breakpointValues[key]}px)`
        },
    },
    easings: {
        easeOut: "cubic-bezier(.17,.67,.35,.99)",
    }
}
