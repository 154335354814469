import {gtagEnabled} from "@utils/tracking/index"
import {Cart} from "@graphql/shopify/generated"

export const trackViewCart = (cart: Cart) => {
    if (gtagEnabled()) {
        gtag("event", "view_cart", {
            currency: cart.estimatedCost.totalAmount.currencyCode,
            value: cart.estimatedCost.totalAmount.amount,
            items: cart.lines.edges.map(item => ({
                item_id: item.node.merchandise.sku,
                item_name: item.node.merchandise.title !== "Default Title" ? item.node.merchandise.title : item.node.merchandise.product.title,
                price: item.node.merchandise.price.amount,
                quantity: item.node.quantity,
            }))
        })
    }
}
