import * as React from "react"
import nl from "./nl"
import en from "./en"
import de from "./de"
//@ts-ignore
import {GB, NL, DE} from "country-flag-icons/react/3x2"
import {ReactNode} from "react";
import {defaultLocale} from "../../constants"
import {i18nMessagesProps, Locales} from "../types"

export const getI18nMessages = (langKey: Locales): i18nMessagesProps => {
    if (langKey === "nl") {
        return nl
    } else if (langKey === "de") {
        return de
    }

    return en
}

export const translationLabels: {[key in Locales]: string} = {
    "nl": "Nederlands",
    "en": "English",
    "de": "Deutsch"
}

export const translationIcons: {[key in Locales]: ReactNode} = {
    "nl": <NL/>,
    "en": <GB/>,
    "de": <DE/>
}

export const getLocaleByUri = (uri?: string) =>{
    if (uri) {
        const match = uri.match('^\/([a-z]{2})\/')
        if (match?.[1] && Object.keys(translationLabels).includes(match[1])) {
            return match[1]
        }
    }

    return defaultLocale
}