const paths = {
  login: "/de/anmeldung/",
  register: "/de/registrieren/",
  "forgot-password": "/de/passwort-vergessen",
  "activate-customer": "/de/konto-aktivieren/:userId/:token",
  "reset-password": "/de/reset-passwort/:userId/:token",
  dashboard: "/de/konto/",
  invalidToken: "/de/konto/invalid_token/",
  orders: "/de/konto/bestellungen/",
  support: "https://knowledgebase.boldsmartlock.com/de/knowledge",
  returns: "https://www.xn--rcksendungen-dlb.de/boldsmartlock",
  faq: "/de/haeufig-gestellte-fragen/",
  products: "/de/produkte/",
}

export default paths
