/*global rdt*/
import * as React from "react"

import {ClientProvider} from "./src/hooks/useClient"
import makeClient from "./src/graphql/shopify/makeClient"
import {StoreProvider} from "./src/context/store-context"
import {theme} from "./src/theme"
import {ThemeProvider} from "@emotion/react"
import {redditEnabled} from "./src/utils/tracking"

export const wrapRootElement = ({element}) => (
    <ClientProvider makeClient={(url) => makeClient(url)}>
        <StoreProvider>
            <ThemeProvider theme={theme}>
                {element}
            </ThemeProvider>
        </StoreProvider>
    </ClientProvider>
)

export const onRouteUpdate = ({ location }) => {
    if (process.env.NODE_ENV === 'production' && redditEnabled()) {
        rdt('track', 'ViewContent')
    }
}
