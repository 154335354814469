exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-activate-customer-tsx": () => import("./../../../src/templates/activate-customer.tsx" /* webpackChunkName: "component---src-templates-activate-customer-tsx" */),
  "component---src-templates-article-detail-tsx": () => import("./../../../src/templates/articleDetail.tsx" /* webpackChunkName: "component---src-templates-article-detail-tsx" */),
  "component---src-templates-author-overview-tsx": () => import("./../../../src/templates/author-overview.tsx" /* webpackChunkName: "component---src-templates-author-overview-tsx" */),
  "component---src-templates-category-overview-tsx": () => import("./../../../src/templates/category-overview.tsx" /* webpackChunkName: "component---src-templates-category-overview-tsx" */),
  "component---src-templates-dashboard-tsx": () => import("./../../../src/templates/dashboard.tsx" /* webpackChunkName: "component---src-templates-dashboard-tsx" */),
  "component---src-templates-forgot-password-tsx": () => import("./../../../src/templates/forgot-password.tsx" /* webpackChunkName: "component---src-templates-forgot-password-tsx" */),
  "component---src-templates-invalid-token-tsx": () => import("./../../../src/templates/invalidToken.tsx" /* webpackChunkName: "component---src-templates-invalid-token-tsx" */),
  "component---src-templates-login-tsx": () => import("./../../../src/templates/login.tsx" /* webpackChunkName: "component---src-templates-login-tsx" */),
  "component---src-templates-news-archive-tsx": () => import("./../../../src/templates/news-archive.tsx" /* webpackChunkName: "component---src-templates-news-archive-tsx" */),
  "component---src-templates-orders-tsx": () => import("./../../../src/templates/orders.tsx" /* webpackChunkName: "component---src-templates-orders-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-product-detail-tsx": () => import("./../../../src/templates/productDetail.tsx" /* webpackChunkName: "component---src-templates-product-detail-tsx" */),
  "component---src-templates-register-tsx": () => import("./../../../src/templates/register.tsx" /* webpackChunkName: "component---src-templates-register-tsx" */),
  "component---src-templates-reset-password-tsx": () => import("./../../../src/templates/reset-password.tsx" /* webpackChunkName: "component---src-templates-reset-password-tsx" */)
}

