import {AUTH_TOKEN, USER_INFO} from "../constants"
import isBrowser from "@utils/isBrowser"

export const isLoggedIn = () => isBrowser && window.sessionStorage.getItem(AUTH_TOKEN)

export const getAuthToken = () => isBrowser ? window.sessionStorage.getItem(AUTH_TOKEN) : null

export const updateCustomer = (customer?: any) => {
    if (!customer) {
        return
    }

    window.sessionStorage.setItem(USER_INFO, JSON.stringify(customer))
}

export const authenticateUser = (data: any) => {
    if (!data?.accessToken) {
        return
    }

    window.sessionStorage.setItem(AUTH_TOKEN, data.accessToken)

    //updateCustomer(data?.customer)
}

export const removeAuthToken = () => {
    window.sessionStorage.removeItem(AUTH_TOKEN)
    window.sessionStorage.removeItem(USER_INFO)
}

export const getCustomer = (): any => {
    if (!isBrowser) {
        return {}
    }

    const customer = window.sessionStorage.getItem(USER_INFO)
    return customer ? JSON.parse(customer) : {}
}