import {useEffect, useState} from "react"
import fetch from "isomorphic-fetch"
import {Bundles} from "@context/types"

const useAllBundles = () => {
    const [bundles, setBundles] = useState<Bundles>()

    useEffect(() => {
        const bundleFromStorage = window.sessionStorage.getItem('bundles')

        if (bundleFromStorage) {
            setBundles(JSON.parse(bundleFromStorage))
        } else if (!bundles) {
            fetch(`https://bundle.revy.io/api/embed?shop=${process.env.GATSBY_SHOPIFY_BASE_STORE_URL}`, {
                headers: {
                    "User-Agent": "Strapi CMS",
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setBundles(data)
                        window.sessionStorage.setItem('bundles', JSON.stringify(data))
                    }
                })
        }
    }, [])

    return bundles
}

export default useAllBundles