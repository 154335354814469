const messages = {
    tokenExpired: 'Token expired',
    tokenExpiredMessage: 'The password reset token has expired.',
    langSwitchLabel: 'Select a language',
    productsUrl: 'products',
    in_stock: 'In stock',
    low_stock: 'Limited stock',
    selectLabel: 'Select',
    mobileSize: 'size',
    myAccount: 'My account',
    addToCart: 'Add to cart',
    newsletterSignup: 'Sign up for our newsletter',
    placeOrder: 'Place order',
    createAnAccount: 'Create an account',
    createAccount: 'Create account',
    register: 'Register',
    forgotMyPassword: 'Forgot my password',
    resetMyPassword: 'Reset my password',
    repeatResetMyPassword: 'Repeat your new password',
    login: 'Login',
    chooseAPassword: 'Choose a password',
    password: 'Password',
    emailAddress: 'Email address',
    genericError: 'Something went wrong, please try again.',
    newsletterSignUpConfirmation: 'Thanks for signing up to our newsletter. Please check your email to confirm.',
    dashboard: 'Dashboard',
    from: 'From',
    needHelp: 'Need help?',
    followVideo: 'Follow our step-by-step',
    instructionVideo: 'size configurator',
    orders: 'orders',
    myProfile: 'My profile',
    myAddress: 'My address',
    myOrders: 'My orders',
    editMyProfile: 'Edit my profile',
    saveProfileDetails: 'Save my profile details',
    firstName: 'First name',
    optional: 'optional',
    lastName: 'Last name',
    phone: 'Phone number',
    emailUpdates: 'Email updates',
    emailUpdatesLabel: "I'd like to receive Bold Smart Lock updates via email",
    address1: 'Street and house number',
    address2: 'Apartment, suite, etc.',
    city: 'City',
    zip: 'Postal code',
    countryRegion: 'Country/region',
    logOut: 'Log out',
    forgotPasswordDescription: 'Enter your email address so we can send you a link where you can reset your password.',
    selectAPage: 'Select a page',
    returns: 'Returns',
    support: 'Support',
    referral: 'Referral',
    writeAReview: 'Write a Review',
    status: 'Status',
    orderDate: 'Order Date',
    orderId: 'Order Id',
    shippingAddress: 'Shipping Address',
    invoiceAddress: 'Invoice Address',
    paymentMethod: 'Payment Method',
    trackAndTrace: 'Track and Trace',
    reviewThisOrder: 'Review this order',
    downloadInvoice: 'Download invoice',
    contactSupport: 'Contact support',
    subtotal: 'Subtotal',
    incVAT: 'Inc. VAT',
    expandThisOrder: 'Expand this order',
    loginDescription: 'Because life’s too short to worry about everything to do with keys.',
    registerDescription: "Once you've made your first order - you can find all the information here.",
    profileUpdated: 'Your profile has been updated.',
    fullLangName: 'English',
    welcome: 'Welcome',
    unsureDescription: "Something you'd like to share or ask? We're happy to help. Anytime, anywhere.",
    stillUnsure: 'Still unsure?',
    lockSizeDescription:
        'Make sure you choose the Bold Smart Lock that fits perfectly. There are 8 different sizes available and which one you need, depends on your door. Match your measurements in our size table and choose your lock:',
    unsureAboutTheSize: 'Unsure about the size?',
    pageNotFound: 'Page not found',
    pageNotFoundBody: 'The page you are looking for cannot be found.<br />We are happy to help you.',
    viewOurFaq: 'View our FAQ',
    viewCart: 'View cart',
    downloadAppStore: "Download the app in your phone's app store",
    navigateToHomepage: 'Navigate to the homepage',
    reduceQuantityByOne: 'Reduce quantity by one',
    addQuantityByOne: 'Add quantity by one',
    viewProduct: 'View product',
    addProductToBundle: 'Add product to bundle',
    viewPreviousPhoto: 'View previous photo',
    viewNextPhoto: 'View next photo',
    discount: 'discount',
    discountLabel: 'Get a :discount% discount',
    phonePlaceholder: '+44',
    onSale: 'On sale',
    emptyCart: 'Your cart is empty',
    emptyCartDescription:
        'Looks like you haven’t added anything Bold to your cart yet. Check out our products to find what you’re looking for and add it to your cart.',
    emptyCartButton: 'Explore our products',
    passwordProtectTitle: 'Password protected',
    passwordProtectDescription: 'This page is protected with a password.',
    passwordProtectLabel: 'Password',
    passwordProtectPlaceholder: 'Fill in the received password',
    passwordProtect: 'Submit password',
    upsellDescription: 'Share it. Whenever, wherever with extra discount',
    businessPortalDescription: 'Easily control your business from wherever you are.',
    combideal: 'Go for the combideal',
    shoppingcartHeader: 'My shopping cart',
    repeatActivatePassword: 'Repeat your password',
    activateAccount: 'Activate your account',
    activateMyAccount: 'Activate account',
    required: 'This field is required.',
    fieldNameRequired: 'The field :fieldName is required.',
    emailInvalid: 'Fill in a valid email address. (jane@doe.com)',
    attachment_size: 'The filesize needs to be smaller than 10 MB.',
    attachment_extension:
        'The selected file cannot be uploaded. Only files with the following extensions are allowed: jpg, jpeg, png, pdf',
    conditionsRequired: 'You need to agree with our terms and conditions.',
    maxChars: 'Fill in a maximum of :max chars.',
    minChars: 'Fill in a maximum of :min chars.',
    exactChars: 'Fill in :minMax chars.',
    equalToPassword: 'Passwords do not match.',
    equalToEmail: 'Email addresses do not match.',
    digitsOnly: 'This field can only contain digits.',
    defaultOption: 'Select an option.',
    continueShopping: 'Continue shopping',
    couponError: 'Coupon :code is invalid or expired',
    couponSuccess: ':discount discount from code :code is automatically applied to your shopping cart',
    buyBundle: 'Buy bundle',
    mostCommon: 'Most common',
    selectA: 'Select :size',
    Size: 'Size',
    Color: 'Color',
    buyMorePayLess: 'Buy more, pay less.',
    automaticDiscount: 'Discount automatically applied at checkout.',
    noCombinedDiscounts: 'Discounts cannot be combined with offers for other products.',
    loginWarningText: 'Please note: These credentials are not related to the Bold-App',
    no_stock: 'Out of stock',
    trustpilotLocale: 'en-GB',
    howWeCollectOurReviews: 'How we collect our reviews',
    reviewCollectionDetails: `<p>
                        Bold’s published reviews are collected and formally checked via the independent third-party
                        <a
                            style='text-decoration: underline'
                            href='https://www.trustpilot.com/review/boldsmartlock.com'
                            target='_blank'
                        >Trustpilot</a>. The reviewers are actual customers, sharing their genuine experience with the Bold products
                        and services. To read more about the review collection process and how does Trustpilot ensure
                        the integrity of their platform click here:
                        <a
                            target='_blank'
                           style='text-decoration: underline'
                            href='https://legal.trustpilot.com/for-reviewers/guidelines-for-reviewers'
                        >https://legal.trustpilot.com/for-reviewers/guidelines-for-reviewers</a>.
                    </p>`,
    size: { desktop: 'a size', mobile: 'size' },
    color: { desktop: 'a color', mobile: 'color' },
    Amount: 'Amount',
    amount: { desktop: 'amount', mobile: 'amount' },
    phoneLandcode: 'Fill in a valid phonenumber, starting with the country code. (i.e. +31)',
    startingFrom: 'Starting from',
    share: 'Share this page',
    newsletterSignupPlaceHolder: 'Your email address',
    articlesUrl: 'blog',
    authorUrl: 'authors',
    categoryUrl: 'categories',
    noArticles: 'There are no articles availlable',
    readMore: 'Read more',
    findYourSize: 'Find out which size you need',
    sx33CorrectSize: 'Find out if SX-33 is the correct size for you.',
    onlyInSx33: 'Keep in mind that the Bold Smart Cylinder (Limited Edition) is available in size SX-33 only.',
    visitMeasurePage: 'Size guide',
    preOrder: 'Pre-order',
    notifyMe: 'Notify me',
    readLess: 'Read less',
    notifyMeContent: `<h2>Want a heads up?</h2><p>Leave your email address and we’ll send you an email when the product is back in stock.</p>`,
    notifyMeThankYou: `<p>Great! You will receive an email once the product is back in stock.</p>`,
    latestOnThisTheme: 'Latest on this theme',
    generalTermsLink: '/terms-and-conditions',
    generalTerms: 'algemene voorwaarden',
    acceptTerms: `<p>Door verder te gaan ga je akkoord met de [termsLink] en  heb je de bestelling en gegevens gecontroleerd.</p>`,
    pleaseAcceptTerms: 'U dient akkoord te gaan met de bestelbevestiging',
    inThisArticle: 'In this article',
    Black: 'Black',
    Silver: 'Silver',
    Brass: 'Brass',
    Copper: 'Copper',
    Orange: 'Orange',
    Green: 'Green',
    Blue: 'Blue',
    Pink: 'Pink',
    'Light Blue': 'Light Blue',
    'Light Grey': 'Light Grey',
    'Light Green': 'Light Green',
    readTime: (readTime: string) => `${readTime} min read`,
    moreOnThisSubject: 'More on this subject',
    exploreThis: 'Explore this',
}

export default messages
