const paths = {
  login: "/login/",
  register: "/register/",
  "forgot-password": "/forgot-password/",
  "activate-customer": "/activate-account/:userId/:token/",
  "reset-password": "/reset-password/:userId/:token/",
  dashboard: "/account/",
  invalidToken: "/account/invalid_token/",
  orders: "/account/orders/",
  support: "https://knowledgebase.boldsmartlock.com/en/knowledge",
  returns: "https://www.returnform.com/boldsmartlock",
  faq: "/frequently-asked-questions/",
  products: "/products/",
}

export default paths
